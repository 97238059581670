.frameChild {
    align-self: stretch;
    height: 452px;
    position: relative;
    filter: blur(300px);
    border-radius: 50%;
    background-color: rgba(32, 184, 205, 0.35);
  }
  .ellipseWrapper {
    margin-left: -571px;
    height: 284.5px;
    width: 456px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 0 176px;
    box-sizing: border-box;
    max-width: 100%;
    flex-shrink: 0;
  }
  .dontMissOutjoin,
  .univibesIsLaunching {
    align-self: stretch;
    position: relative;
    letter-spacing: 0.2px;
    white-space: nowrap;
    z-index: unset;
  }
  .univibesIsLaunching {
    margin: 0;
    font-size: inherit;
    line-height: 120%;
    font-weight: 700;
    font-family: inherit;
  }
  .dontMissOutjoin {
    font-size: var(--font-size-base);
    line-height: 125%;
    color: var(--black-grey-text-bg-grey-n);
  }
  .univibesIsLaunchingSoonParent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
    z-index: unset;
  }
  .textInput {
    border: 0;
    background-color: transparent;
    height: 54px;
    flex: 1;
    font-family: var(--font-lato);
    font-size: var(--font-size-base);
    color: var(--black-grey-text-bg-grey-n);
    max-width: 100%;
    z-index: unset;
  }
  .buttonDesktop {
    height: 52px;
    width: 139px;
    z-index: unset;
  }
  .textInputParent {
    width: 476px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-base);
    max-width: 100%;
    z-index: unset;
  }
  .footerIconsChild {
    position: absolute;
    top: 81px;
    left: 167.9px;
    filter: blur(300px);
    border-radius: 50%;
    background-color: var(--color-dodgerblue-100);
    width: 360px;
    height: 358px;
    z-index: unset;
  }
  .footerIconsItem {
    position: absolute;
    top: 0;
    left: 0;
    width: 176px;
    height: 189.8px;
    object-fit: contain;
    z-index: 1;
  }
  .footerIcons {
    width: 444.9px;
    height: 334.9px;
    position: absolute;
    margin: 0 !important;
    right: -361px;
    bottom: -171.4px;
    z-index: unset;
  }
  .frameContainer {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    gap: var(--gap-29xl);
    max-width: 100%;
    z-index: unset;
  }
  .copyright2024 {
    width: 314px;
    position: relative;
    letter-spacing: 0.2px;
    line-height: 22px;
    display: inline-block;
    white-space: nowrap;
  }
  .copyright2024UnivibesAlWrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0 20px;
    font-size: var(--font-size-sm);
    color: var(--black-grey-text-bg-grey-n);
  }
  .frameGroup,
  .frameParent,
  .page {
    display: flex;
    justify-content: flex-start;
  }
  .frameGroup {
    width: 718px;
    flex-direction: column;
    align-items: flex-start;
    gap: 65.5px;
    max-width: 100%;
    flex-shrink: 0;
  }
  .frameParent,
  .page {
    flex-direction: row;
    box-sizing: border-box;
  }
  .frameParent {
    width: 1344px;
    border-radius: var(--br-6xl);
    background-color: var(--cyan-brand-cyan-800);
    overflow: hidden;
    flex-shrink: 0;
    align-items: flex-end;
    padding: 111.6px 313px 23.9px;
    gap: 115px;
    max-width: 100%;
    text-align: center;
    font-size: var(--font-size-37xl);
    color: var(--black-grey-text-bg-grey-n1);
    font-family: var(--font-lato);
  }
  .page {
    position: relative;
    width: 100%;
    align-items: flex-start;
    padding: var(--padding-41xl) var(--padding-29xl);
    line-height: normal;
    letter-spacing: normal;
  }
  @media screen and (max-width: 1200px) {
    .frameParent {
      padding-left: var(--padding-137xl);
      padding-right: var(--padding-137xl);
      box-sizing: border-box;
    }
  }
  
.bgChild {
  align-self: stretch;
  flex: 1;
  position: relative;
  backdrop-filter: blur(1.5rem); /* Adjusted from 50px to 3.125rem */
  background-color: rgba(0, 20, 23, 0.3);
  z-index: 0;
}
.bgInner,
.bgItem {
  margin: 0 !important;
  filter: blur(1rem); /* Adjusted from 300px to 3.125rem */
  border-radius: 50%;
}
.bgItem {
  width: 22.5rem;
  position: absolute;
  top: 24.625rem;
  left: 75.188rem;
  background-color: var(--blueinfo-blue);
  height: 22.375rem;
  opacity: 0.3;
  z-index: 1;
}
.bgInner {
  width: 28.5rem;
  top: -12rem;
  left: -12.125rem;
  background-color: var(--cyan-brand-cyan-500-p);
  height: 28.25rem;
}
.bg,
.bgInner,
.heroChild {
  position: absolute;
}
.bg {
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  background-image: url(/public/bg2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.heroChild {
  top: 6.719rem;
  left: 52.563rem;
  width: 19.1rem;
  height: 19.856rem;
  object-fit: contain;
}
.discoverAllThe,
.univibes {
  position: relative;
  letter-spacing: 0.2px;
}
.univibes {
  align-self: stretch;
  line-height: 120%;
}
.discoverAllThe {
  width: 26.875rem;
  font-size: var(--font-size-base);
  line-height: 125%;
  color: var(--black-grey-text-bg-grey-n);
  display: inline-block;
} 
.catchYourUniversityVibesParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.enterYourEmail {
  flex: 1;
  position: relative;
  line-height: 1.375rem;
}
.textInput {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--black-grey-text-bg-grey-n1);
  height: 3.375rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-base);
  box-sizing: border-box;
}
.label {
  position: relative;
  letter-spacing: 0.2px;
  line-height: 125%;
}
.bookmarkAddIcon {
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.buttonDesktop,
.textInputParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.buttonDesktop {
  border-radius: var(--br-xs);
  background-color: var(--cyan-brand-cyan-500-p);
  justify-content: center;
  padding: var(--padding-base) var(--padding-5xl);
  gap: var(--gap-5xs);
  color: var(--white-white);
}
.textInputParent {
  width: 29.75rem;
  justify-content: flex-start;
  gap: var(--gap-base);
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--black-grey-text-bg-grey-n);
}
.frameParent,
.univibesParent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.frameParent {
  align-self: stretch;
  justify-content: center;
  gap: var(--gap-29xl);
  font-size: var(--font-size-37xl);
  color: var(--black-grey-text-bg-grey-n1);
}
.univibesParent {
  position: absolute;
  top: 2.813rem;
  left: calc(50% - 359px);
  width: 44.875rem;
  justify-content: flex-start;
  gap: 7.5rem;
}
.pathIcon {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 19.063rem;
}
.pathParent {
  position: absolute;
  top: 5.894rem;
  left: 0;
  width: 90.313rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding-left: 2rem;
  padding-right: 2rem;
}
.polaroidIcon {
  position: absolute;
  top: 0;
  left: calc(50% - 172.35px);
  border-radius: 6.33px;
  width: 21.875rem;
  height: 26.181rem;
  overflow: hidden;
  object-fit: contain;
}
.polaroidIcon1 {
  top: 2.988rem;
  left: 18.263rem;
  border-radius: 4.51px;
  width: 15.306rem;
  height: 18.438rem;
}
.polaroidIcon1,
.polaroidIcon2,
.polaroidIcon3,
.polaroidIcon4 {
  position: absolute;
  overflow: hidden;
  object-fit: contain;
}
.polaroidIcon2 {
  top: 10.456rem;
  left: 0;
  border-radius: 4.22px;
  width: 14.888rem;
  height: 17.706rem;
}
.polaroidIcon3,
.polaroidIcon4 {
  top: 7.244rem;
  left: 61.488rem;
  border-radius: 4.8px;
  width: 16.281rem;
  height: 19.613rem;
}
.polaroidIcon4 {
  top: 3.056rem;
  left: 80.956rem;
  border-radius: 4.03px;
  width: 14.469rem;
  height: 17.106rem;
}
.polaroidParent {
  top: 0;
  left: calc(50% - 763.45px);
  width: 95.431rem;
}
.frameGroup,
.groupWrapper,
.polaroidParent {
  position: absolute;
  height: 28.163rem;
}
.groupWrapper {
  top: 0;
  left: calc(50% - 711.5px);
  width: 95.431rem;
  overflow-x: hidden;
  /* padding-left: 65rem; */
  margin-right: 105rem;
}
.frameGroup {
  top: 32.044rem;
  left: -9.312rem;
  width: 101.925rem;
  padding: 3rem;
  overflow: hidden;

}
.hero,
.superchargeYourUniversity {
  position: relative;
  text-align: center;
}
.hero {
  width: 100vw;
  align-self: stretch;
  height: 100vh;
  font-size: var(--font-size-5xl);
  color: var(--cyan-brand-cyan-500-p);
  font-family: var(--font-lato);
}
.superchargeYourUniversity {
  letter-spacing: 0.2px;
  line-height: 120%;
  padding-bottom: 5rem;
}
.stayInThe,
.textInputGroup {
  font-size: var(--font-size-base);
}
.textInputGroup {
  width: 29.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  color: var(--black-grey-text-bg-grey-n);
}
.stayInThe {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.2px;
  line-height: 125%;
}
.neverMissOutOnTheVibeParent {
  background-color: '#F2FAF6';
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.eventCardFeatured,
.eventCardFeatured1 {
  width: 18.194rem;
  margin: 0 !important;
  position: absolute;
  bottom: 1.488rem;
  left: calc(50% - 167.7px);
  border-radius: 7.77px;
  height: 10.181rem;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
  z-index: 0;
}
.eventCardFeatured1 {
  width: 18.794rem;
  bottom: -1.031rem;
  left: calc(50% - 114.1px);
  height: 10.544rem;
  z-index: 1;
}
.frameChild {
  width: 4.619rem;
  position: relative;
  height: 4.619rem;
  object-fit: cover;
}
.title {
  align-self: stretch;
  position: relative;
  line-height: 125%;
}
.clockChild {
  position: absolute;
  height: 16.89%;
  width: 12.16%;
  top: 33.11%;
  right: 37.84%;
  bottom: 50%;
  left: 50%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.clockItem {
  position: absolute;
  height: 75%;
  width: 75%;
  top: 12.16%;
  right: 12.84%;
  bottom: 12.84%;
  left: 12.16%;
  border-radius: 8.45px;
  border: 1.2px solid var(--black-grey-text-bg-grey-n);
  box-sizing: border-box;
}
.clock,
.title1 {
  position: relative;
}
.clock {
  width: 0.925rem;
  background-color: var(--white-white);
  height: 0.925rem;
}
.title1 {
  line-height: 125%;
}
.clockParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-11xs-8);
}
.locationIcon {
  width: 0.925rem;
  position: relative;
  height: 0.925rem;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.locationParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-11xs-8);
}
.frameParent1,
.titleParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.frameParent1 {
  width: 11.663rem;
  justify-content: flex-start;
  gap: 0.231rem;
  font-size: var(--font-size-xs);
  color: var(--black-grey-text-bg-grey-n);
}
.titleParent {
  flex: 1;
  justify-content: center;
  gap: 0.343rem;
}
.groupParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs-1);
}
.label2,
.title3 {
  position: relative;
}
.title3 {
  flex: 1;
  line-height: 125%;
}
.label2 {
  letter-spacing: 0.18px;
  line-height: 1.271rem;
}
.bookmarkAddIcon2 {
  width: 1.388rem;
  position: relative;
  height: 1.388rem;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button,
.titleGroup {
  border-radius: 9.24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.button {
  background-color: var(--cyan-brand-cyan-500-p);
  justify-content: center;
  padding: 0.375rem var(--padding-sm);
  gap: 0.462rem;
  color: var(--white-white);
}
.titleGroup {
  align-self: stretch;
  background-color: var(--cyan-brand-cyan-50-n);
  justify-content: flex-start;
  padding: 0.462rem 0.693rem;
  gap: 0.925rem;
  font-size: var(--font-size-xs);
  color: var(--cyan-brand-cyan-500-p);
}
.eventCardFeatured2 {
  align-self: stretch;
  box-shadow: var(--shadow1);
  border-radius: 9.71px;
  background-color: var(--white-white);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-sm);
  gap: var(--gap-xs-1);
  z-index: 2;
}
.highlight2Icon {
  width: 2.6rem;
  position: absolute;
  margin: 0 !important;
  top: -1.8rem;
  left: 18.625rem;
  height: 3.275rem;
  z-index: 3;
}
.eventCardFeaturedParent {
  width: 21.25rem;
  margin: 0 !important;
  position: absolute;
  top: calc(50% - 74.8px);
  left: calc(50% - 177px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
.frameWrapper {
  align-self: stretch;
  height: 15rem;
  justify-content: flex-start;
  position: relative;
  font-size: var(--font-size-sm);
  color: var(--black);
}
.frameDiv,
.frameWrapper,
.getTheVibeRightParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.frameDiv {
  align-self: stretch;
  width: 27rem;
  border-radius: var(--br-3xs);
  background-color: var(--greensuccess-green-n);
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  padding: var(--padding-29xl) 1.75rem;
  box-sizing: border-box;
  gap: var(--gap-29xl);
}
.getTheVibeRightParent {
  flex: 1;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.ellipseIcon,
.frameInner,
.frameItem {
  width: 1.838rem;
  position: relative;
  border-radius: 50%;
  height: 1.838rem;
  object-fit: cover;
  z-index: 2;
}
.ellipseIcon,
.frameInner {
  z-index: 1;
  margin-left: -0.882rem;
}
.ellipseIcon {
  z-index: 0;
}
.ellipseParent,
.frameParent5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.frameParent5 {
  position: absolute;
  top: 0;
  left: 3.375rem;
  border-radius: var(--br-341xl);
  background-color: var(--cyan-brand-cyan-500-p);
  padding: var(--padding-9xs) var(--padding-5xs) var(--padding-9xs)
    var(--padding-9xs);
  gap: var(--gap-9xs);
}
.frameChild1 {
  width: 1.838rem;
  position: relative;
  border-radius: 50%;
  height: 1.838rem;
  object-fit: cover;
  z-index: 0;
}
.frameParent6 {
  position: absolute;
  top: 3.813rem;
  left: 0;
  border-radius: var(--br-341xl);
  background-color: var(--orangewarning-orange);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-9xs) var(--padding-5xs) var(--padding-9xs)
    var(--padding-9xs);
  gap: var(--gap-9xs);
}
.emojiFireIcon {
  width: 54.42%;
  position: absolute;
  margin: 0 !important;
  height: 62.93%;
  top: 18.37%;
  right: 21.77%;
  bottom: 18.71%;
  left: 23.81%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}
.ellipseDiv {
  width: 1.838rem;
  position: relative;
  border-radius: 50%;
  height: 1.838rem;
  z-index: 0;
}
.emojiFireParent,
.frameParent7 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.frameParent7 {
  position: absolute;
  top: 7.25rem;
  left: 9rem;
  border-radius: var(--br-341xl);
  background-color: var(--blueinfo-blue);
  padding: var(--padding-9xs) var(--padding-5xs) var(--padding-9xs)
    var(--padding-9xs);
}
.frameParent4 {
  position: absolute;
  top: 1.65rem;
  left: 0.481rem;
  width: 18.963rem;
  height: 9.588rem;
}
.groupChild,
.groupDiv {
  width: 21.138rem;
  position: absolute;
  height: 12.25rem;
}
.groupChild {
  top: 0;
  left: 0;
}
.groupDiv {
  margin: 0 !important;
  top: 1.375rem;
  left: 2.875rem;
  z-index: 0;
}
.frameParent3,
.groupContainer {
  display: flex;
  justify-content: flex-start;
}
.groupContainer {
  width: 26.875rem;
  height: 15rem;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  font-size: var(--font-size-xs);
  color: var(--white-white);
}
.frameParent3 {
  align-self: stretch;
  border-radius: var(--br-3xs);
  background-color: var(--orangewarning-orange-n);
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0 var(--padding-29xl);
}
.groupItem {
  position: absolute;
  top: 0.244rem;
  left: 9.156rem;
  width: 9.838rem;
  height: 8.137rem;
  object-fit: contain;
}
.frameChild2 {
  width: 3.5rem;
  position: relative;
  border-radius: 50%;
  height: 3.5rem;
  object-fit: cover;
}
.title8 {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-xs);
  line-height: 125%;
  color: var(--black-grey-text-bg-grey-n);
}
.ellipseContainer,
.ellipseGroup,
.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.ellipseContainer,
.ellipseGroup {
  position: absolute;
  border-radius: var(--br-3xs);
  background-color: var(--white-white);
  width: 11.875rem;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-xs);
  box-sizing: border-box;
  gap: var(--gap-5xs);
}
.ellipseGroup {
  top: 0;
  left: 0;
}
.ellipseContainer {
  top: 6.563rem;
  left: 8.313rem;
}
.groupParent2 {
  position: absolute;
  top: 0;
  left: 1.188rem;
  width: 20.188rem;
  height: 11.563rem;
}
.label3 {
  position: relative;
  line-height: 120%;
  color: var(--cyan-brand-cyan-500-p);
}
.button1 {
  top: 2.438rem;
  left: 0;
  width: 8.313rem;
  box-sizing: border-box;
}
.button1,
.button2,
.button3 {
  position: absolute;
  box-shadow: var(--shadow);
  border-radius: var(--br-7xs);
  background-color: var(--cyan-brand-cyan-50-n);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-9xs) var(--padding-5xs);
  gap: var(--gap-9xs);
}
.button2 {
  top: 0;
  left: 2.813rem;
}
.button3 {
  top: 4.875rem;
  left: 2.125rem;
}
.buttonParent {
  position: absolute;
  top: 4.438rem;
  left: 0;
  width: 12.125rem;
  height: 6.313rem;
  font-size: var(--font-size-xs);
}
.groupParent1 {
  width: 21.375rem;
  position: absolute;
  margin: 0 !important;
  top: 1.75rem;
  left: 2.75rem;
  height: 11.563rem;
  z-index: 0;
}
.frameParent8,
.groupFrame {
  display: flex;
  justify-content: flex-start;
}
.groupFrame {
  width: 26.875rem;
  height: 15rem;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  font-size: var(--font-size-sm);
  color: var(--black);
}
.frameParent8 {
  align-self: stretch;
  border-radius: var(--br-3xs);
  background-color: var(--blueinfo-blue-n);
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0 var(--padding-29xl);
}
.frameContainer,
.frameParent2 {
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.frameParent2 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}
.frameContainer {
  width: '100vw';
  height: auto;
  flex-direction: row;
  align-items: center;
  font-size: var(--font-size-5xl);
}
.empowerYourSocietys,
.featureForStudent {
  align-self: stretch;
  font-size: var(--font-size-37xl);
}

.empowerYourSocietys {
  position: relative;
  letter-spacing: 0.2px;
  line-height: 120%;
  text-align: center;
}
.textInputContainer {
  width: 29.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  color: var(--black-grey-text-bg-grey-n);
}
.folderIcon {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.folderWrapper {
  width: 3rem;
  border-radius: var(--br-5xs-5);
  background-color: var(--cyan-brand-cyan-50-n);
  height: 3rem;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs);
  box-sizing: border-box;
}
.frameParent10,
.frameParent9 {
  /* align-self: stretch; */
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent10 {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-5xl);
  gap: var(--gap-base);
}
.frameParent9 {
  width: 100vw;
  /* padding: 0 5rem 0 5rem; */
  flex-direction: row;
  gap: 2.125rem;
  font-size: var(--font-size-5xl);
}
.frame335Transformed1Icon {
  position: absolute;
  top: 0;
  left: 8.981rem;
  border-radius: 6.8px;
  width: 45.7rem;
  height: 25.806rem;
  object-fit: cover;
}
.groupIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 7.419rem;
  height: 7.138rem;
}
.frameChild4 {
  width: 1.8rem;
  position: relative;
  border-radius: 50%;
  height: 1.8rem;
  object-fit: cover;
}
.ellipseParent1 {
  position: absolute;
  top: 4.438rem;
  left: 2.875rem;
  box-shadow: var(--shadow1);
  border-radius: 0 var(--br-3xs) var(--br-3xs) var(--br-3xs);
  background-color: var(--cyan-brand-cyan-50-n);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs);
  gap: var(--gap-8xs-8);
}
.groupGroup {
  position: absolute;
  top: 4.963rem;
  left: 0;
  width: 18.538rem;
  height: 7.238rem;
}
.trendupIcon {
  width: 1rem;
  position: relative;
  height: 1rem;
  overflow: hidden;
  flex-shrink: 0;
}
.titleParent2 {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.title16 {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 125%;
  color: var(--black);
}
.frameParent15 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.625rem;
}
.frameChild5,
.frameChild6,
.frameChild7,
.frameChild8,
.frameChild9 {
  width: 2.206rem;
  position: relative;
  border-radius: 50%;
  height: 2.206rem;
  object-fit: cover;
  z-index: 4;
}
.frameChild6,
.frameChild7,
.frameChild8,
.frameChild9 {
  z-index: 3;
  margin-left: -1.057rem;
}
.frameChild7,
.frameChild8,
.frameChild9 {
  z-index: 2;
}
.frameChild8,
.frameChild9 {
  z-index: 1;
}
.frameChild9 {
  z-index: 0;
}
.frameParent14 {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: var(--shadow1);
  border-radius: var(--br-xs);
  background-color: var(--blueinfo-blue-n);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-base);
  gap: 0.6rem;
}
.emojiU1f929Icon {
  position: absolute;
  top: 3.438rem;
  left: 9.5rem;
  width: 5.244rem;
  height: 5.244rem;
  overflow: hidden;
  object-fit: contain;
}
.frameParent13 {
  position: absolute;
  top: 3.438rem;
  left: 0;
  width: 14.744rem;
  height: 8.681rem;
}
.bellIcon {
  width: 1.2rem;
  position: relative;
  height: 1.2rem;
  overflow: hidden;
  flex-shrink: 0;
}
.newEventDate {
  position: relative;
  line-height: 135%;
}
.bellParent,
.frameParent16 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bellParent {
  justify-content: flex-start;
  gap: var(--gap-8xs-8);
}
.frameParent16 {
  align-self: stretch;
  justify-content: space-between;
}
.notificationNotice {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: var(--shadow1);
  border-radius: var(--br-xs);
  background-color: var(--orangewarning-orange-n);
  width: 13.25rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.6rem;
  box-sizing: border-box;
  font-size: var(--font-size-base);
  color: var(--orangewarning-orange);
}
.groupParent3 {
  position: absolute;
  top: 13.525rem;
  left: 48.531rem;
  width: 14.744rem;
  height: 12.119rem;
  font-size: 1.8rem;
  color: var(--blueinfo-blue);
}
.frame335Transformed1Parent {
  width: 63.275rem;
  position: relative;
  height: 25.806rem;
}
.empowerYourSocietysEventsParent,
.groupWrapper1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--cyan-brand-cyan-500-p);
}
.empowerYourSocietysEventsParent {
  padding: var(--padding-41xl) var(--padding-29xl);
  gap: var(--gap-45xl);
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--black-grey-text-bg-black);
  font-family: var(--font-lato);
}
.landingPageWaitlistDesktop {
  width: 100%;
  position: relative;
  background-color: var(--white-white);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

@media screen and (max-width: 1100px) {
  .neverMissOutOnTheVibeParentParent {
    /* Add your styles here */
  background-color: '#F2FAF6';
  height: 20%;
  display: flex;
  justify-content: flex-start;
  align-self: stretch;
  border-radius: var(--br-3xs);
  background-color: #f2faf6;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0 var(--padding-29xl);

  }
}

@media screen and (max-width: 1100px) {
  .neverMissOutOnTheVibeParentParent2 {
    /* Add your styles here */
  height: 24%;
  display: flex;
  justify-content: flex-start;
  align-self: stretch;
  background-color: '#fff9ee';
  border-radius: var(--br-3xs);
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0 var(--padding-29xl);

  }
}

@media screen and (max-width: 1100px) {
  .neverMissOutOnTheVibeParentParent3 {
    /* Add your styles here */
  height: 20%;
  display: flex;
  justify-content: flex-start;
  align-self: stretch;
  border-radius: var(--br-3xs);
  background-color: '#F1F8FF';
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0 var(--padding-29xl);

  }
}


@media screen and (max-width: 1100px) {
  .neverMissOutOnTheVibeParent {
    /* Add your styles here */
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      justify-content: center;
      gap: var(--gap-5xs);
      background-color: '#F2FAF6';
  }
  
}


@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-lato: Lato;

  /* font sizes */
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-37xl: 3.5rem;
  --font-size-5xl: 1.5rem;
  --font-size-xs: 0.75rem;

  /* Colors */
  --black-grey-text-bg-grey-n: #888c8c;
  --black-grey-text-bg-black: #1b1f1f;
  --black: #1c1b1f;
  --cyan-brand-cyan-800: #001417;
  --blueinfo-blue: #3b82f6;
  --color-dodgerblue-100: rgba(59, 130, 246, 0.3);
  --black-grey-text-bg-grey-n1: #eff1f5;
  --cyan-brand-cyan-500-p: #20b8cd;
  --white-white: #fff;
  --orangewarning-orange-n: #fff9ee;
  --orangewarning-orange: #ffad0d;
  --blueinfo-blue-n: #f1f8ff;
  --cyan-brand-cyan-50-n: #ecfdff;
  --greensuccess-green-n: #f2faf6;

  /* Gaps */
  --gap-29xl: 3rem;
  --gap-base: 1rem;
  --gap-5xs: 0.5rem;
  --gap-45xl: 4rem;
  --gap-8xs-8: 0.3rem;
  --gap-9xs: 0.25rem;
  --gap-5xl: 1.5rem;
  --gap-xs-1: 0.693rem;
  --gap-11xs-8: 0.112rem;

  /* Paddings */
  --padding-41xl: 3.75rem;
  --padding-29xl: 3rem;
  --padding-137xl: 9.75rem;
  --padding-base: 1rem;
  --padding-5xl: 1.5rem;
  --padding-xs: 0.75rem;
  --padding-5xs: 0.5rem;
  --padding-3xs: 0.625rem;
  --padding-9xs: 0.25rem;
  --padding-sm: 0.875rem;

  /* Border radiuses */
  --br-6xl: 25px;
  --br-xs: 12px;
  --br-3xs: 10px;
  --br-5xs-5: 7.5px;
  --br-7xs: 6px;
  --br-341xl: 360px;
  --br-3xs-2: 9.2px;
  --br-5xs-8: 7.8px;

  /* Effects */
  --shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  --shadow1: 0px 5px 15px rgba(0, 0, 0, 0.15);
}

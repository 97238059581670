.emojiU1f973Icon {
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
}
.awesomeYoureIn {
  position: relative;
  letter-spacing: 0.2px;
  line-height: 120%;
}
.emojiU1f973Parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.b,
.queueNo {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.2px;
  line-height: 120%;
}
.b {
  font-size: var(--font-size-37xl);
  color: var(--cyan-brand-cyan-500-p);
}
.frameGroup,
.queueNoParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.queueNoParent {
  gap: var(--gap-9xs);
  text-align: center;
  font-size: var(--font-size-5xl);
}
.frameGroup {
  gap: var(--gap-5xl);
}
.frameGroup,
.moveUpThe {
  align-self: stretch;
}
.label,
.moveUpThe {
  position: relative;
  letter-spacing: 0.2px;
  line-height: 125%;
}
.bookmarkAddIcon {
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.buttonDesktop {
  align-self: stretch;
  border-radius: var(--br-3xs);
  background-color: var(--cyan-brand-cyan-500-p);
  height: 2.375rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-base);
  box-sizing: border-box;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--white-white);
}
.frameParent,
.moveUpTheQueueBySharingUParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.moveUpTheQueueBySharingUParent {
  border-radius: var(--br-3xs);
  background-color: var(--cyan-brand-cyan-50-n);
  overflow: hidden;
  justify-content: center;
  padding: var(--padding-base) var(--padding-5xs);
  gap: var(--gap-base);
  text-align: center;
  color: var(--cyan-brand-cyan-500-p);
}
.frameParent {
  flex: 1;
  justify-content: flex-end;
  gap: var(--gap-29xl);
  z-index: 0;
}
.xIcon {
  width: 1rem;
  position: absolute;
  margin: 0 !important;
  top: 1rem;
  right: 1rem;
  height: 1rem;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.popUpMobile {
  width: 18.75rem;
  position: relative;
  border-radius: var(--br-3xs);
  background-color: var(--white-white);
  height: 25.313rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-5xl);
  max-width: 100%;
  max-height: 100%;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--black-grey-text-bg-black);
  font-family: var(--font-lato);
}

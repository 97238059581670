.dontMissOutjoin,
.univibesIsLaunching {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.2px;
  line-height: 120%;
}
.dontMissOutjoin {
  font-size: var(--font-size-base);
  line-height: 125%;
  color: var(--white-white); /* Changed from var(--black-grey-text-bg-grey) to var(--white-white) */
}
.univibesIsLaunchingSoonParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.enterYourEmail {
  flex: 1;
  position: relative;
  line-height: 22px;
}
.textInput {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--white-white); /* Changed from var(--black-grey-text-bg-grey-n) to var(--white-white) */
  height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-base);
  box-sizing: border-box;
}
.label {
  position: relative;
  letter-spacing: 0.2px;
  line-height: 125%;
}
.bookmarkAddIcon {
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.frameContainer,
.textInputParent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.textInputParent {
  width: 476px;
  flex-direction: row;
  gap: var(--gap-base);
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--white-white); /* Changed from var(--black-grey-text-bg-grey) to var(--white-white) */
}
.frameContainer {
  position: absolute;
  top: 10%;
  left: calc(50% - 359px);
  width: 718px;
  flex-direction: column;
  gap: var(--gap-29xl);
}
.copyright2024 {
  position: absolute;
  bottom: 23.9px;
  top: 90%;
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust for half of the element's width */
  font-size: var(--font-size-sm);
  letter-spacing: 0.2px;
  line-height: 22px;
  color: var(--white-white); /* Changed from var(--black-grey-text-bg-grey) to var(--white-white) */
}
.frameChild,
.frameItem {
  position: absolute;
  top: -231.9px;
  left: -258px;
  filter: blur(300px);
  border-radius: 50%;
  background-color: var(--cyan-brand-cyan-500-p);
  width: 456px;
  height: 452px;
  opacity: 0.35;
}
.frameItem {
  top: 226.1px;
  left: 1115px;
  background-color: var(--blueinfo-blue);
  width: 360px;
  height: 358px;
  opacity: 0.3;
}
.frameInner {
  position: absolute;
  top: 145.1px;
  left: 947.1px;
  width: 176px;
  height: 189.8px;
  object-fit: contain;
}
.frameGroup {
  width: 100%;
  align-self: stretch;
  position: relative;
  border-radius: 2.8rem 2.8rem 0 0;
  background-color: var(--cyan-brand-cyan-800);
  height: 420px;
  overflow: hidden;
  flex-shrink: 0;
}
.frameParent {
  width: 100vw;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-37xl);
  color: var(--white-white); /* Changed from var(--black-grey-text-bg-grey-n) to var(--white-white) */
  font-family: var(--font-lato);
}
.bgChild {
  align-self: stretch;
  flex: 1;
  position: relative;
  backdrop-filter: blur(41.21px);
  background-color: var(--color-gray-100);
  z-index: 0;
}
.bgItem {
  width: '100%';
  top: 25.25rem;
  left: 15.125rem;
  filter: blur(247.27px);
  border-radius: 50%;
  background-color: var(--blueinfo-blue);
  height: 18.444rem;
  opacity: 0.3;
  z-index: 1;
}
.bg,
.bgInner,
.bgItem {
  margin: 0 !important;
  position: absolute;
}
.bgInner {
  width: '100%';
  top: -9.887rem;
  left: -9.994rem;
  filter: blur(247.27px);
  border-radius: 50%;
  background-color: var(--cyan-brand-cyan-500-p);
  height: 23.281rem;
  opacity: 0.35;
  z-index: 2;
}
.bg {
  width: '100%';
  /* top: 0; */
  /* left: calc(50% - 195px); */
  height: '100vh';
  object-fit: cover;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.412rem;
  /* background-image: url(/public/bg1@3x.png); */
  background-size: cover;
  /* background-repeat: no-repeat; */
  background-position: center;
  z-index: 0;
}
.univibes {
  margin: 0;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
}
.catchYourUniversity,
.discoverAllThe,
.univibes {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.1px;
  line-height: 120%;
}
.discoverAllThe {
  font-size: var(--font-size-base);
  line-height: 125%;
  color: var(--black-grey-text-bg-grey);
}
.catchYourUniversityVibesParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.textInput {
  border: 0;
  background-color: transparent;
  align-self: stretch;
  flex: 1;
  font-family: var(--font-lato);
  font-size: var(--font-size-sm);
  color: var(--black-grey-text-bg-grey);
}
.buttonMobile {
  cursor: pointer;
}
.textInputParent {
  margin: 0;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.frameParent,
.univibesParent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.frameParent {
  align-self: stretch;
  justify-content: center;
  gap: var(--gap-5xl);
  font-size: var(--font-size-29xl);
  color: var(--black-grey-text-bg-grey-n);
}
.univibesParent {
  width: '100%';
  justify-content: flex-start;
  gap: 3.75rem;
  z-index: 1;
}
.pathIcon {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 9.35rem;
}
.pathParent {
  position: absolute;
  top: 2.894rem;
  left: 0;
  width: 44.306rem;
  height: 9.35rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.polaroidIcon {
  position: absolute;
  top: 0;
  left: calc(50% - 84.6px);
  border-radius: 3.1px;
  width: '100%';
  height: 12.844rem;
  overflow: hidden;
  object-fit: contain;
}
.polaroidIcon1 {
  top: 1.463rem;
  left: 8.956rem;
  border-radius: 2.21px;
  width: 7.506rem;
  height: 9.044rem;
}
.polaroidIcon1,
.polaroidIcon2,
.polaroidIcon3,
.polaroidIcon4 {
  position: absolute;
  overflow: hidden;
  object-fit: contain;
}
.polaroidIcon2 {
  top: 5.131rem;
  left: 0;
  border-radius: 2.07px;
  width: 7.306rem;
  height: 8.688rem;
}
.polaroidIcon3,
.polaroidIcon4 {
  top: 3.55rem;
  left: 30.163rem;
  border-radius: 2.36px;
  width: 7.988rem;
  height: 9.619rem;
}
.polaroidIcon4 {
  top: 1.5rem;
  left: 39.713rem;
  border-radius: 1.98px;
  width: 7.1rem;
  height: 8.394rem;
}
.frameGroup,
.polaroidParent {
  position: absolute;
  height: 13.813rem;
}
.polaroidParent {
  width: '100%';
}
.frameGroup {
  top: 2.125rem;
  left: -14.812rem;
  width: 50rem;
}
.imgFrame {
  width: '100%';
  position: relative;
  height: 17.5rem;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}
.hero,
.superchargeYourUniversity {
  align-self: stretch;
  position: relative;
}
.hero {
  /* background-color: var(--white-white); */
  background-image: url(/public/bg.png);
  width: '100%'; /* Remove quotes */
  backdrop-filter: blur(41.21px);
  object-fit: cover;
  height: 100%; /* Remove quotes */

  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  gap: 0;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--cyan-brand-cyan-500-p);
  font-family: var(--font-lato);

  /* Adjustments for background image */
  background-size: 100% 100%; /* Make sure the entire image fits within the section */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-position: center; /* Center the background image within the element */
}
.superchargeYourUniversity {
  margin: 0;
  font-size: inherit;
  letter-spacing: 0.2px;
  line-height: 120%;
  font-weight: 700;
  font-family: inherit;
}
.superchargeYourUniversityExParent,
.textInputGroup {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.superchargeYourUniversityExParent {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-5xl);
}
.neverMissOut,
.stayInThe {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.2px;
  line-height: 120%;
}
.stayInThe {
  font-size: var(--font-size-base);
}
.neverMissOutOnTheVibeParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.eventCardFeatured,
.eventCardFeatured1 {
  width: 14.556rem;
  margin: 0 !important;
  position: absolute;
  bottom: 1.206rem;
  left: calc(50% - 134.2px);
  border-radius: 6.22px;
  height: 8.181rem;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
  z-index: 0;
}
.eventCardFeatured1 {
  width: 15.031rem;
  bottom: -0.812rem;
  left: calc(50% - 91.2px);
  height: 8.419rem;
  z-index: 1;
}
.frameChild {
  width: 3.694rem;
  position: relative;
  height: 3.694rem;
  object-fit: cover;
}
.title {
  align-self: stretch;
  position: relative;
  line-height: 125%;
}
.clockChild {
  position: absolute;
  height: 16.95%;
  width: 12.71%;
  top: 33.05%;
  right: 37.29%;
  bottom: 50%;
  left: 50%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.clockItem {
  position: absolute;
  height: 75.42%;
  width: 75.42%;
  top: 12.71%;
  right: 11.86%;
  bottom: 11.86%;
  left: 12.71%;
  border-radius: 6.76px;
  border: 1px solid var(--black-grey-text-bg-grey);
  box-sizing: border-box;
}
.clock,
.title1 {
  position: relative;
}
.clock {
  width: 0.738rem;
  background-color: var(--white-white);
  height: 0.738rem;
}
.title1 {
  line-height: 125%;
}
.clockParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-11xs-5);
}
.locationIcon {
  width: 0.738rem;
  position: relative;
  height: 0.738rem;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.locationParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-11xs-5);
}
.frameParent1,
.titleParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.frameParent1 {
  width: 9.331rem;
  justify-content: flex-start;
  gap: 0.187rem;
  font-size: var(--font-size-3xs-6);
  color: var(--black-grey-text-bg-grey);
}
.titleParent {
  flex: 1;
  justify-content: center;
  gap: 0.275rem;
}
.groupParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-4xs-9);
}
.label,
.title3 {
  position: relative;
}
.title3 {
  flex: 1;
  line-height: 125%;
}
.label {
  letter-spacing: 0.15px;
  line-height: 1.016rem;
}
.bookmarkAddIcon {
  width: 1.106rem;
  position: relative;
  height: 1.106rem;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.button,
.titleGroup {
  border-radius: 7.39px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.button {
  background-color: var(--cyan-brand-cyan-500-p);
  justify-content: center;
  padding: 0.3rem var(--padding-xs-2);
  gap: 0.368rem;
  color: var(--white-white);
}
.titleGroup {
  align-self: stretch;
  background-color: var(--cyan-brand-cyan-50-n);
  justify-content: flex-start;
  padding: 0.368rem 0.556rem;
  gap: 0.737rem;
  font-size: var(--font-size-3xs-6);
  color: var(--cyan-brand-cyan-500-p);
}
.eventCardFeatured2 {
  align-self: stretch;
  box-shadow: 0 5.2px 21.43px rgba(100, 100, 111, 0.2);
  border-radius: 7.77px;
  background-color: var(--white-white);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-xs-2);
  gap: var(--gap-4xs-9);
  z-index: 2;
}
.highlight2Icon {
  width: 2.081rem;
  position: absolute;
  margin: 0 !important;
  top: -1.437rem;
  left: 14.9rem;
  height: 2.619rem;
  z-index: 3;
}
.eventCardFeaturedParent {
  width: 17rem;
  margin: 0 !important;
  position: absolute;
  top: calc(50% - 56px);
  left: calc(50% - 136px);
  box-shadow: 0 3px 8.87px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
.frameDiv,
.imgFrame1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.imgFrame1 {
  height: 12.5rem;
  position: relative;
  text-align: left;
  font-size: var(--font-size-xs-2);
  color: var(--black);
}
.frameDiv {
  border-radius: var(--br-3xs);
  background-color: var(--greensuccess-green-n);
  overflow: hidden;
  padding: var(--padding-17xl) var(--padding-base);
  gap: var(--gap-base);
}
.ellipseIcon,
.frameInner,
.frameItem {
  width: 1.469rem;
  position: relative;
  border-radius: 50%;
  height: 1.469rem;
  object-fit: cover;
  z-index: 2;
}
.ellipseIcon,
.frameInner {
  z-index: 1;
  margin-left: -0.707rem;
}
.ellipseIcon {
  z-index: 0;
}
.ellipseParent,
.frameParent3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.frameParent3 {
  position: absolute;
  top: 0;
  left: 2.7rem;
  border-radius: var(--br-269xl);
  background-color: var(--cyan-brand-cyan-500-p);
  padding: var(--padding-9xs-2) var(--padding-6xs-4) var(--padding-9xs-2)
    var(--padding-9xs-2);
  gap: var(--gap-9xs-2);
}
.frameChild1 {
  width: 1.469rem;
  position: relative;
  border-radius: 50%;
  height: 1.469rem;
  object-fit: cover;
  z-index: 0;
}
.frameParent4 {
  position: absolute;
  top: 2.9rem;
  left: 0;
  border-radius: var(--br-269xl);
  background-color: var(--orangewarning-orange);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-9xs-2) var(--padding-6xs-4) var(--padding-9xs-2)
    var(--padding-9xs-2);
  gap: var(--gap-9xs-2);
}
.emojiFireIcon {
  width: 54.47%;
  position: absolute;
  margin: 0 !important;
  height: 62.98%;
  top: 18.72%;
  right: 21.7%;
  bottom: 18.3%;
  left: 23.83%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}
.ellipseDiv {
  width: 1.469rem;
  position: relative;
  border-radius: 50%;
  height: 1.469rem;
  z-index: 0;
}
.emojiFireParent,
.frameParent5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.frameParent5 {
  position: absolute;
  top: 5.8rem;
  left: 7.2rem;
  border-radius: var(--br-269xl);
  background-color: var(--blueinfo-blue);
  padding: var(--padding-9xs-2) var(--padding-6xs-4) var(--padding-9xs-2)
    var(--padding-9xs-2);
}
.groupDiv {
  position: absolute;
  top: 1.319rem;
  left: 0.381rem;
  width: 15.144rem;
  height: 7.669rem;
}
.groupChild,
.groupContainer {
  width: 16.463rem;
  position: absolute;
  height: 9.8rem;
}
.groupChild {
  top: 0;
  left: 0;
}
.groupContainer {
  margin: 0 !important;
  top: 1.375rem;
  left: 1.938rem;
  z-index: 0;
}
.frameParent2,
.imgFrame2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.imgFrame2 {
  height: 12.5rem;
  position: relative;
  text-align: left;
  font-size: var(--font-size-3xs-6);
  color: var(--white-white);
}
.frameParent2 {
  border-radius: var(--br-3xs);
  overflow: hidden;
  padding: var(--padding-17xl) var(--padding-base);
  gap: var(--gap-base);
}

.groupItem {
  position: absolute;
  top: 0.194rem;
  left: 7.325rem;
  width: 7.869rem;
  height: 6.506rem;
  object-fit: contain;
}
.frameChild2 {
  width: 2.8rem;
  position: relative;
  border-radius: 50%;
  height: 2.8rem;
  object-fit: cover;
}
.title8 {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-3xs-6);
  line-height: 125%;
  color: var(--black-grey-text-bg-grey);
}
.ellipseContainer,
.ellipseGroup,
.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.ellipseContainer,
.ellipseGroup {
  position: absolute;
  border-radius: var(--br-5xs);
  background-color: var(--white-white);
  width: 9.5rem;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-3xs-6);
  box-sizing: border-box;
  gap: var(--gap-6xs-4);
}
.ellipseGroup {
  top: 0;
  left: 0;
}
.ellipseContainer {
  top: 5.25rem;
  left: 6.65rem;
}
.groupParent2 {
  position: absolute;
  top: 0;
  left: 0.95rem;
  width: 16.15rem;
  height: 9.25rem;
}
.label1 {
  position: relative;
  line-height: 120%;
  color: var(--cyan-brand-cyan-500-p);
}
.button1 {
  top: 1.95rem;
  left: 0;
  width: 6.65rem;
  box-sizing: border-box;
}
.button1,
.button2,
.button3 {
  position: absolute;
  box-shadow: 0 3.2px 9.6px rgba(0, 0, 0, 0.1);
  border-radius: var(--br-8xs-8);
  background-color: var(--cyan-brand-cyan-50-n);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-9xs-2) var(--padding-6xs-4);
  gap: var(--gap-9xs-2);
}
.button2 {
  top: 0;
  left: 2.25rem;
}
.button3 {
  top: 3.9rem;
  left: 1.7rem;
}
.buttonParent {
  position: absolute;
  top: 3.55rem;
  left: 0;
  width: 9.7rem;
  height: 5.05rem;
  font-size: var(--font-size-3xs-6);
}
.groupParent1 {
  width: 17.1rem;
  position: absolute;
  margin: 0 !important;
  top: 1.625rem;
  left: 2.438rem;
  height: 9.25rem;
  z-index: 0;
}
.frameParent6 {
  border-radius: var(--br-3xs);
  background-color: var(--blueinfo-blue-n);
  overflow: hidden;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-17xl) var(--padding-base);
  gap: var(--gap-base);
}
.featureSection,
.frameContainer,
.frameParent6 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.frameContainer {
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-5xl);
  font-size: var(--font-size-5xl);
}
.featureSection {
  background-color: var(--white-white);
  overflow: hidden;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-29xl) var(--padding-base);
  gap: var(--gap-29xl);
}
.enterYourEmail {
  flex: 1;
  position: relative;
  line-height: 1.375rem;
}
.textInput2 {
  align-self: stretch;
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--black-grey-text-bg-grey-n);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-base);
}
.label4 {
  position: relative;
  letter-spacing: 0.1px;
  line-height: 125%;
}
.bookmarkAddIcon1 {
  width: 0.75rem;
  position: relative;
  height: 0.75rem;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.buttonMobile2,
.textInputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.buttonMobile2 {
  border-radius: var(--br-3xs);
  background-color: var(--cyan-brand-cyan-500-p);
  justify-content: center;
  padding: var(--padding-sm) var(--padding-base);
  gap: var(--gap-9xs);
  cursor: pointer;
  color: var(--white-white);
}
.textInputContainer {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--black-grey-text-bg-grey);
}
.folderIcon {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.folderWrapper {
  width: 3rem;
  border-radius: var(--br-5xs-5);
  background-color: var(--cyan-brand-cyan-50-n);
  height: 3rem;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs);
  box-sizing: border-box;
}
.trackSignUpsMake {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: 0.2px;
  line-height: 125%;
}
.frameParent7,
.frameParent8 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-base);
}
.frameParent7 {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-29xl);
  font-size: var(--font-size-5xl);
}
.frame335Transformed1Icon {
  position: absolute;
  top: 0;
  left: 1.281rem;
  border-radius: 3.21px;
  width: 21.581rem;
  height: 12.188rem;
  object-fit: cover;
}
.groupIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 3.931rem;
  height: 3.788rem;
}
.frameChild4 {
  width: 0.956rem;
  position: relative;
  border-radius: 50%;
  height: 0.956rem;
  object-fit: cover;
}
.ellipseParent1 {
  position: absolute;
  top: 2.35rem;
  left: 1.525rem;
  box-shadow: 0 2.7px 7.96px rgba(0, 0, 0, 0.15);
  border-radius: 0 var(--br-7xs-3) var(--br-7xs-3) var(--br-7xs-3);
  background-color: var(--cyan-brand-cyan-50-n);
  width: 8.488rem;
  height: 1.488rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.262rem;
  box-sizing: border-box;
  gap: var(--gap-10xs-5);
}
.groupGroup {
  position: absolute;
  top: 4.894rem;
  left: 0;
  width: 10.013rem;
  height: 3.838rem;
}
.trendupIcon {
  width: 0.531rem;
  position: relative;
  height: 0.531rem;
  overflow: hidden;
  flex-shrink: 0;
}
.titleParent2 {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  gap: 0.131rem;
}
.title16 {
  position: relative;
  font-size: var(--font-size-4xs-5);
  line-height: 125%;
  color: var(--black);
}
.frameParent13 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.331rem;
}
.frameChild5,
.frameChild6,
.frameChild7,
.frameChild8,
.frameChild9 {
  width: 1.169rem;
  position: relative;
  border-radius: 50%;
  height: 1.169rem;
  object-fit: cover;
  z-index: 4;
}
.frameChild6,
.frameChild7,
.frameChild8,
.frameChild9 {
  z-index: 3;
  margin-left: -0.563rem;
}
.frameChild7,
.frameChild8,
.frameChild9 {
  z-index: 2;
}
.frameChild8,
.frameChild9 {
  z-index: 1;
}
.frameChild9 {
  z-index: 0;
}
.frameParent12 {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2.7px 7.96px rgba(0, 0, 0, 0.15);
  border-radius: 6.36px;
  background-color: var(--blueinfo-blue-n);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0.531rem;
  gap: 0.318rem;
}
.emojiU1f929Icon {
  position: absolute;
  top: 1.825rem;
  left: 5.038rem;
  width: 2.781rem;
  height: 2.781rem;
  overflow: hidden;
  object-fit: contain;
}
.frameParent11 {
  position: absolute;
  top: 1.825rem;
  left: 0;
  width: 7.825rem;
  height: 4.606rem;
}
.bellIcon {
  width: 0.638rem;
  position: relative;
  height: 0.638rem;
  overflow: hidden;
  flex-shrink: 0;
}
.newEventDate {
  position: relative;
  line-height: 135%;
}
.bellParent,
.frameParent14 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bellParent {
  justify-content: flex-start;
  gap: var(--gap-10xs-5);
}
.frameParent14 {
  align-self: stretch;
  justify-content: space-between;
}
.notificationNotice {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2.7px 7.96px rgba(0, 0, 0, 0.15);
  border-radius: 6.36px;
  background-color: var(--orangewarning-orange-n);
  width: 7.025rem;
  height: 1.388rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.318rem;
  box-sizing: border-box;
  font-size: var(--font-size-4xs-5);
  color: var(--orangewarning-orange);
}
.groupParent3 {
  position: absolute;
  top: 8.706rem;
  left: 16.738rem;
  width: 7.825rem;
  height: 6.431rem;
  font-size: 0.956rem;
  color: var(--blueinfo-blue);
}
.frame335Transformed1Parent {
  width: 24.563rem;
  position: absolute;
  margin: 0 !important;
  top: 1.438rem;
  left: -0.062rem;
  height: 15.138rem;
  z-index: 0;
}
.featureSection1,
.imgFrame4 {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.imgFrame4 {
  width: 24.375rem;
  height: 17.5rem;
  justify-content: center;
  position: relative;
  text-align: left;
  font-size: var(--font-size-4xs-5);
  color: var(--cyan-brand-cyan-500-p);
}
.featureSection1 {
  align-self: stretch;
  background-color: var(--white-white);
  overflow-y: auto;
  justify-content: flex-start;
  padding: var(--padding-29xl) var(--padding-base);
  gap: var(--gap-29xl);
}
.footerChild,
.footerItem {
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: -8.562rem;
  left: -7.687rem;
  filter: blur(150px);
  border-radius: 50%;
  background-color: var(--cyan-brand-cyan-500-p);
  height: 14.125rem;
  opacity: 0.35;
  z-index: 0;
}
.footerItem {
  width: 100%;
  top: 33.625rem;
  left: 17.75rem;
  background-color: var(--blueinfo-blue);
  height: 11.188rem;
  opacity: 0.3;
  z-index: 1;
}
.dontMissOutjoin {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: 0.2px;
  line-height: 125%;
  color: var(--black-grey-text-bg-grey);
}
.frameParent15 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xl);
  z-index: 2;
}
/* Updated CSS for center alignment */
.copyright2024 {
  width: 19.625rem;
  position: absolute;
  margin: 0 !important;
  top: 70%; /* Adjusted for vertical centering */
  /* left: 50%; */
  font-size: var(--font-size-sm);
  letter-spacing: 0.2px;
  line-height: 1.375rem;
  color: var(--black-grey-text-bg-grey);
  display: inline-block;
  z-index: 3;
}
.footer,
.landingPageWaitlistMobile {
  position: relative;
  display: flex;
  flex-direction: column;
}
.footer {
  width: 100%;
  border-radius: 2.3rem 2.3rem 0 0;
  background-color: var(--cyan-brand-cyan-800);
  height: 42.5rem;
  overflow: hidden;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-29xl) var(--padding-base);
  box-sizing: border-box;
  font-size: var(--font-size-29xl);
  color: var(--black-grey-text-bg-grey-n);

/* Adjust border-radius to only affect top-left and top-right */
border-radius: 2.rem 2.5rem 0 0;
}
.landingPageWaitlistMobile {
  width: 100%;
  overflow-y: auto;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--font-size-15xl);
  color: var(--black-grey-text-bg-black);
  font-family: var(--font-lato);
}


@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-lato: Lato;

  /* font sizes */
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-37xl: 3.5rem;
  --font-size-5xl: 1.5rem;
  --font-size-xs: 0.75rem;
  --font-size-29xl: 3rem;
  --font-size-4xs-5: 0.531rem;
  --font-size-15xl: 2.125rem;
  --font-size-3xs-6: 0.6rem;
  --font-size-xs-2: 0.7rem;

  /* Colors */
  --black-grey-text-bg-grey: #888c8c;
  --black-grey-text-bg-black: #1b1f1f;
  --black: #1c1b1f;
  --cyan-brand-cyan-800: #001417;
  --color-gray-100: rgba(0, 20, 23, 0.3);
  --blueinfo-blue: #3b82f6;
  --color-dodgerblue-100: rgba(59, 130, 246, 0.3);
  --black-grey-text-bg-grey-n: #eff1f5;
  --cyan-brand-cyan-500-p: #20b8cd;
  --white-white: #fff;
  --orangewarning-orange-n: #fff9ee;
  --orangewarning-orange: #ffad0d;
  --blueinfo-blue-n: #f1f8ff;
  --cyan-brand-cyan-50-n: #ecfdff;
  --greensuccess-green-n: #f2faf6;

  /* Gaps */
  --gap-29xl: 3rem;
  --gap-base: 1rem;
  --gap-5xs: 0.5rem;
  --gap-45xl: 4rem;
  --gap-8xs-8: 0.3rem;
  --gap-9xs: 0.25rem;
  --gap-5xl: 1.5rem;
  --gap-xs-1: 0.693rem;
  --gap-11xs-8: 0.112rem;
  --gap-10xs-5: 0.156rem;
  --gap-9xs-2: 0.2rem;
  --gap-6xs-4: 0.4rem;
  --gap-4xs-9: 0.556rem;
  --gap-11xs-5: 0.093rem;

  /* Paddings */
  --padding-41xl: 3.75rem;
  --padding-29xl: 3rem;
  --padding-137xl: 9.75rem;
  --padding-xl: 1.25rem;
  --padding-base: 1rem;
  --padding-5xl: 1.5rem;
  --padding-xs: 0.75rem;
  --padding-3xs-6: 0.6rem;
  --padding-5xs: 0.5rem;
  --padding-3xs: 0.625rem;
  --padding-9xs: 0.25rem;
  --padding-sm: 0.875rem;
  --padding-17xl: 2.25rem;
  --padding-9xs-2: 0.2rem;
  --padding-6xs-4: 0.4rem;
  --padding-xs-2: 0.7rem;

  /* Border radiuses */
  --br-6xl: 25px;
  --br-xs: 12px;
  --br-3xs: 10px;
  --br-5xs-5: 7.5px;
  --br-7xs: 6px;
  --br-341xl: 360px;
  --br-3xs-2: 9.2px;
  --br-5xs-8: 7.8px;
  --br-8xs-8: 4.8px;
  --br-6xs-4: 6.4px;
  --br-7xs-3: 5.3px;
  --br-5xs: 8px;
  --br-269xl: 288px;
  --br-5xs-4: 7.4px;
  --br-6xs-2: 6.2px;

  /* Effects */
  --shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  --shadow1: 0px 5px 15px rgba(0, 0, 0, 0.15);
}

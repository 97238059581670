.primaryButton {
    width: 100%; /* Make the button take the full width of its container */
    display: flex;
    flex-direction: row;
    margin-top: 5%;
    border: 10%;
    color: black;
    background-color: rgba(255, 255, 255, 0.7);
    font-style: normal;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    font-weight: 600;
    letter-spacing: 0.14rem; /* Increase letter spacing */
  }
  
  .primaryButton:hover {
    background-color: rgba(255, 255, 255, 1); /* Make background fully opaque */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
  }
  

  .bg {
    background-image: 'url(/public/bg1@3x.png)';
    background-color: rgba(255, 255, 255, 0.7);
  }